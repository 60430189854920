import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'

import SplashTwo from '../../components/splashTwo'
import CallOut from '../../components/callOut'

const FloodRepairPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "water damage restoration services",
	  "alternateName": [
		  "flood damage restoration services",
		  "flood damage repair services",
		  "water damage repair services"
	  ],
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "water damage restoration services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "water damage restoration services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "water damage repair services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "flood damage repair services"
                }
              },
			  {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "water damage restoration services"
                }
              },
			  {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "water damage repair services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Water Damage Restoration Services | Water Damage Repair Services"
        description="Water damage restoration &amp; repair services for homes, strata's and apartments. We'll restore your property to it's like-new condition!"
        canonical="https://ecotechrestoration.ca/services/flood-damage-repair-and-restoration/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/flood.jpg"
          quality={95}
          alt="Water Damage Restoration"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Flood &amp; Water Damage Restoration Services
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>
          Do you have a Flood and Water Damage Restoration Emergency?
        </CallOut>
        <p>
          Water damage takes on many different forms. It can arrive from a
          flood, saturating all of your belongings at once, or it can slowly
          spread through unseen places due to a leaky pipe or poorly maintained
          HVAC system. Regardless of the cause, Ecotech's water damage
          restoration technicians have the specialized equipment and training
          necessary to restore your property to its as-is or better condition.
        </p>
        <p>
          With our professional-grade equipment, we'll collect the water from
          your home or business' hard-to-reach-areas that risk creating pockets
          of moisture that you can't see or reach that foster mould growth.
          These pockets of moisture inevitably lead to a mould problem that will
          damage your health, home and possessions.
        </p>
        <p>
          Our restoration company is open 24 hours a day, 7 days a week. Our
          technicians bring our state-of-the-art equipment to your property in
          minutes to begin the restoration process immediately!
        </p>
        <h2>24 Hour Water Damage Restoration Cleanup Services</h2>
        <p>
          We offer emergency water damage services with technicians available
          the minute your call comes in.
        </p>
        <p>
          What does "emergency service" mean to us? It means our friendly staff
          will be there to speak with you the moment you need us, in every way
          you need us.
        </p>
        <p>
          Our highly-trained specialists provide comprehensive services in
          various other areas, such as mould remediation. This is important
          because water damage can evolve into something more troublesome. In
          this case, our technicians will look for and deal with it before it
          develops into something worse and more costly because our experts are
          also IICRC certified.
        </p>
        <h2>Water Damage Restoration Procedures</h2>
        <p>
          Water Pump-outs, Sewage Clean-ups, Dehumidification,
          Sterilization(Anti- microbial) Removal of Damaged Building Materials,
          Content Protection & Restoration, Mold Prevention (Mitigation), Damage
          (building & Contents fully Documented)
        </p>
        <h2>Emergency Flood CLean-up</h2>
        <p>
          Whether it's a burst pipe, sprinkler line, exterior drain tile
          failure, cracked foundation, or excess rainfall from a storm surge,
          our dedicated water damage restoration techs will do everything it
          takes to dry out your space and restore your home to its as-new or
          better condition.
        </p>
        <p>
          When we arrive, we first perform a visual inspection. After assessing
          the situation, we will immediately split our team into groups to begin
          moving out your belongings to safely store them and deal with any
          current leaks and damage. Once your belongings are safe, and the
          immediate water leaks are taken care of, we'll begin extracting the
          water and then bring in professional- grade drying equipment to fully
          dry and dehumidify your space.
        </p>
        <p>
          Again, we understand that water damage risks developing into mould
          damage. It takes only 24 hours for mould spores to grow on damp
          surfaces. Therefore, we'll make sure that you receive an immediate
          mould inspection. This includes surveying your drywall, sheetrock, and
          any hidden spaces where mould colonies are prone to grow. If we find
          mould, we'll remove the mould promptly and make sure we prevent future
          growth.
        </p>
        <p>
          Our dedicated water remediation team won't just clean up your
          property. We will fully restore it to its as-new or better condition.
        </p>
        <h2>Insurance Consultation For Water Damage Repair</h2>
        <p>
          Do you know how your insurance provider will cover water damage and to
          what extent?
        </p>
        <p>
          If not, that's ok. Most people don't. And in reality, every water
          emergency is unique and tying it all in with how your insurance
          coverage works is tricky for someone unfamiliar with it all.
        </p>
        <p>
          Our staff knows how to work with your insurance company make sense of
          it all. We'll figure out all of your coverage details and help you
          complete the necessary paperwork.
        </p>
        <p>
          We'll file your claim for you on your behalf and bill your provider
          directly.
        </p>
        <p>
          Restoring your home to the same or better condition is easier than you
          think. Let us deal with the details.
        </p>
        <p>
          Our water damage repair and mould remediation specialists at EcoTech
          Restoration can't wait to hear from you.
        </p>

        <SplashTwo title="Water Damage Restoration" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default FloodRepairPage
